import React, { PropsWithChildren } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Layout } from '@/components/layout';
import { Auth0UrqlProvider } from '@/components/urql';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

const PrivateRouter = React.lazy(() => import('./private'));
const LoginPage = React.lazy(() => import('@/pages/login'));
const ShipperListPage = React.lazy(() => import('@/pages/shipper'));
const ShipperNewPage = React.lazy(() => import('@/pages/shipper/new'));
const ShipperEditPage = React.lazy(() => import('@/pages/shipper/[id]/edit'));
const ShipperCompanyListPage = React.lazy(() => import('@/pages/shipper-company'));
const ShipperCompanyNewPage = React.lazy(() => import('@/pages/shipper-company/new'));
const ShipperCompanyEditPage = React.lazy(() => import('@/pages/shipper-company/[id]/edit'));
const ShipperEmployeeListPage = React.lazy(() => import('@/pages/supplier-employee'));
const ShipperEmployeeNewPage = React.lazy(() => import('@/pages/supplier-employee/new'));
const SupplierEmployeeEditPage = React.lazy(() => import('@/pages/supplier-employee/[employeeId]/edit'));
const SupplierListPage = React.lazy(() => import('@/pages/supplier'));
const SupplierNewPage = React.lazy(() => import('@/pages/supplier/new'));
const SupplierEditPage = React.lazy(() => import('@/pages/supplier/[supplierId]/edit'));
const ProjectListPage = React.lazy(() => import('@/pages/project'));
const ProjectDetailPage = React.lazy(() => import('@/pages/project/[projectId]'));
const EstimateNewPage = React.lazy(() => import('@/pages/project/[projectId]/estimates/new'));
const EstimateEditPage = React.lazy(() => import('@/pages/project/[projectId]/estimates/[estimateId]/edit'));
const TimelinePage = React.lazy(() => import('@/pages/project/[projectId]/contact-rooms/[contactRoomId]/timelines'));
const TimelineDetailPage = React.lazy(
  () => import('@/pages/project/[projectId]/contact-rooms/[contactRoomId]/timelines/[timelineId]'),
);
const NewEstimateRequestPage = React.lazy(() => import('@/pages/estimate-request/new'));
const ContainerECListPage = React.lazy(() => import('@/pages/containerec'));
const NewContainerECPage = React.lazy(() => import('@/pages/containerec/new'));

const NotFound = React.lazy(() => import('@/pages/notfound'));
const MaintenancePage = React.lazy(() => import('@/pages/maintenance'));

const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
const client = import.meta.env.VITE_AUTH0_CLIENT_ID as string;

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      // cacheLocation="memory"
    >
      {children}
    </Auth0Provider>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Auth0UrqlProvider>
          <Routes>
            <Route element={<PrivateRouter />}>
              <Route element={<Layout />}>
                <Route path="/" element={<ProjectListPage />} />
                <Route path="/containerec" element={<ContainerECListPage />} />
                <Route path="/containerec/new" element={<NewContainerECPage />} />
                <Route path="/estimate_requests/new" element={<Navigate replace to="/estimate-requests/new" />} />
                <Route path="/estimate-requests/new" element={<NewEstimateRequestPage />} />
                <Route path="/shippers" element={<ShipperListPage />} />
                <Route path="/shippers/new" element={<ShipperNewPage />} />
                <Route path="/shippers/:id/edit" element={<ShipperEditPage />} />
                <Route path="/shipper-companies" element={<ShipperCompanyListPage />} />
                <Route path="/shipper-companies/new" element={<ShipperCompanyNewPage />} />
                <Route path="/shipper-companies/:id/edit" element={<ShipperCompanyEditPage />} />
                <Route path="/suppliers" element={<SupplierListPage />} />
                <Route path="/suppliers/new" element={<SupplierNewPage />} />
                <Route path="/suppliers/:id/edit" element={<SupplierEditPage />} />
                <Route path="/suppliers/:id/employees" element={<ShipperEmployeeListPage />} />
                <Route path="/suppliers/:id/employees/new" element={<ShipperEmployeeNewPage />} />
                <Route path="/suppliers/:id/employees/:employeeId/edit" element={<SupplierEmployeeEditPage />} />
                <Route path="/projects" element={<ProjectListPage />} />
                <Route path="/projects/:projectId" element={<ProjectDetailPage />} />
                <Route path="/projects/:projectId/estimates/new" element={<EstimateNewPage />} />
                <Route path="/projects/:projectId/estimates/:estimateId/edit" element={<EstimateEditPage />} />

                <Route
                  path="/projects/:projectId/contact_rooms/:contactRoomId/timelines/:timelineId"
                  element={<RedirectTimelines />}
                />

                <Route path="/projects/:projectId/contact-rooms/:contactRoomId/timelines" element={<TimelinePage />}>
                  <Route
                    path="/projects/:projectId/contact-rooms/:contactRoomId/timelines/:timelineId"
                    element={<TimelineDetailPage />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Auth0UrqlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

const RedirectTimelines = () => {
  const params = useParams<{
    timelineId: 'timelineId';
    contactRoomId: 'contactRoomId';
    projectId: 'projectId';
  }>() as {
    timelineId: string;
    contactRoomId: string;
    projectId: string;
  };

  if (params.timelineId) {
    return (
      <Navigate
        replace
        to={`/projects/${params.projectId}/contact-rooms/${params.contactRoomId}/timelines/${params.timelineId}`}
      />
    );
  }

  return <Navigate replace to={`/projects/${params.projectId}/contact-rooms/${params.contactRoomId}/timelines`} />;
};
