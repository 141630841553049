import { Layout } from '../layout';
import { InternalServerError as UIInternalServerError } from '../ui-parts/error/InternalServerError';

export const InternalServerError = () => {
  return (
    <Layout>
      <UIInternalServerError />
    </Layout>
  );
};
