import clsx from 'clsx';
import { SidebarContext, SidebarProvider } from './SidebarContext';

export type SidebarProps = {
  children: React.ReactElement;
  defaultOpen?: boolean;
};

export const Sidebar: React.FC<SidebarProps> = ({ defaultOpen = true, children }) => {
  return (
    <SidebarProvider defaultOpen={defaultOpen}>
      <SidebarContext.Consumer>
        {({ open }) => (
          <div
            className={clsx([
              'bg-shade-white-default hidden-scrollbar sticky top-14 z-50 mx-0 flex h-full py-6',
              'transition-all ease-in-out',
              open ? 'w-72' : 'w-[56px]',
            ])}
          >
            <div className="hidden-scrollbar flex grow flex-col overflow-y-auto">{children}</div>
          </div>
        )}
      </SidebarContext.Consumer>
    </SidebarProvider>
  );
};
