import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem } from '@4design/for-ui';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar } from '@mui/material';

export const Header = React.memo(() => {
  const { user, logout } = useAuth0();

  const handleLogout = React.useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
  const STAGINGURI = 'stg-giho-willbox.jp.auth0.com' as string;
  const STAGING = 'Staging' as string;
  const displayStaging = domain === STAGINGURI ? STAGING : '';

  return (
    <div className="bg-notice-dark-default z-header sticky top-0 flex h-14 w-full min-w-full flex-row items-center justify-between p-2 pr-6 shadow-lg">
      {/* left */}
      <Link to="/">
        <div className="flex items-center gap-3.5">
          <img src="/images/logo/logo.png" width="40" height="40" alt="logo" />
          <div className="text-lg font-bold">Giho</div>
          <p className="text-lg font-bold">{displayStaging}</p>
        </div>
      </Link>

      {/* right */}

      <Menu
        TriggerComponent={
          <div className="flex cursor-pointer items-center gap-3.5">
            <div className="text-base text-[#333333]">{user?.email}</div>
            <Avatar sx={{ width: 32, height: 32 }}>{user?.email?.charAt(0)}</Avatar>
          </div>
        }
      >
        <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
      </Menu>
    </div>
  );
});
