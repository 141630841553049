import { SnackbarProvider } from 'notistack';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { InternalServerError } from './components/error/InternalServerError';
import { Router } from './router';

export const App = () => {
  const rootElement = window.document.getElementById('root');
  const theme = createTheme({
    components: {
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={InternalServerError} showDialog>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Sentry.ErrorBoundary>
  );
};
