import React from 'react';
import clsx from 'clsx';
import { match } from 'path-to-regexp';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineShoppingCart } from 'react-icons/hi2';
import {
  MdFolderOpen,
  MdHome,
  MdInsertLink,
  MdLockOutline,
  MdMailOutline,
  MdOutlineCorporateFare,
  MdOutlineDirectionsBoatFilled,
  MdOutlineStickyNote2,
  MdTag,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar as UISidebar } from '@/components/ui-parts/layout/sidebar';
import { SidebarContext } from '@/components/ui-parts/layout/sidebar/SidebarContext';
import { SidebarMenu, SidebarMenuItem } from '@/components/ui-parts/layout/sidebar/SidebarMenu';

export const Sidebar = React.memo(() => {
  const location = useLocation();

  return (
    <UISidebar defaultOpen={false}>
      <SidebarContext.Consumer>
        {({ open, handleOpen, handleClose }) => (
          <>
            <SidebarMenu>
              {open ? (
                <div onClick={handleClose}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center justify-end p-4 font-medium">
                    <HiOutlineChevronDoubleLeft size={24} className={clsx(['icon-shade-dark-default'])} />
                  </div>
                </div>
              ) : (
                <div onClick={handleOpen}>
                  <div className="text-shade-dark-default text-r flex cursor-pointer items-center  p-4 font-medium">
                    <HiOutlineChevronDoubleRight size={24} className={clsx(['icon-shade-dark-default'])} />
                  </div>
                </div>
              )}

              <Link to="/">
                <SidebarMenuItem
                  IconComponent={MdHome}
                  label="ホーム"
                  active={
                    !!match('/', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/estimate-requests/new">
                <SidebarMenuItem
                  IconComponent={MdTag}
                  label="見積依頼代行"
                  active={
                    !!match('/estimate-requests/new', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/suppliers">
                <SidebarMenuItem
                  IconComponent={MdOutlineDirectionsBoatFilled}
                  label="業者管理"
                  active={
                    !!match('/suppliers(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/shipper-companies">
                <SidebarMenuItem
                  IconComponent={MdOutlineCorporateFare}
                  label="荷主管理"
                  active={
                    !!match('/shippers(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname) ||
                    !!match('/shipper-companies(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/projects">
                <SidebarMenuItem
                  IconComponent={MdFolderOpen}
                  label="案件管理"
                  active={
                    !!match('/projects(.*)', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <Link to="/containerec">
                <SidebarMenuItem
                  IconComponent={HiOutlineShoppingCart}
                  label="コンテナEC"
                  active={
                    !!match('/containerec', {
                      decode: decodeURIComponent,
                    })(location.pathname)
                  }
                />
              </Link>

              <div className="mt-auto">
                {/* <hr className="border-shade-medium-default my-2 mx-4" /> */}

                <a href="https://willbox.jp/contact" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={MdMailOutline} label="お問合せ" />
                </a>

                <a href="https://giho.willbox.jp/privacy" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={MdLockOutline} label="プライバシーポリシー" />
                </a>

                <a href="https://willbox.jp/terms" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={MdOutlineStickyNote2} label="利用規約" />
                </a>

                <a href="https://willbox.jp" target="_blank" rel="noreferrer">
                  <SidebarMenuItem IconComponent={MdInsertLink} label="©2023Willbox,Inc." />
                </a>
              </div>
            </SidebarMenu>
          </>
        )}
      </SidebarContext.Consumer>
    </UISidebar>
  );
});
